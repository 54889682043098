import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Index",
		component: () => import(/* webpackChunkName: "404" */ "../views/DataLookup.vue"),
		meta: {
			requiresAuth: false,
			title: "Tasky Tools",
		},
	},
	{
		path: "/task/:task",
		name: "Task",
		component: () => import(/* webpackChunkName: "Task" */ "../views/Task.vue"),
		meta: {
			requiresAuth: false,
			title: "Tasky Tools",
		},
		props: true
	},
	{
		path: "/flowers",
		name: "Flowers",
		component: () => import(/* webpackChunkName: "flowers" */ "../views/Task.vue"),
		meta: {
			requiresAuth: false,
			title: "Tasky Tools",
		},
	},
	{
		path: "/Bunnies",
		name: "Bunnies",
		component: () => import(/* webpackChunkName: "flowers" */ "../views/Task.vue"),
		meta: {
			requiresAuth: false,
			title: "Tasky Tools",
		},
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

// navigation guard to check for logged in users
// eslint-disable-line no-unused-vars
router.beforeEach(async (to, from, next) => {
	document.title = "Tasky Tools";
	next();

	/*const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
	const roles = to.meta.roles;
	if (auth.currentUser) {
		let app = await router.app;

		console.log("router", app.$store);
		app.$store.dispatch("updateActivity");
	}
	if (roles && roles.length > 0 && auth.currentUser) {
		auth.currentUser.getIdTokenResult().then((tokenResult) => {
			//console.log(tokenResult.claims);
			for (const index in roles) {
				const role = roles[index];
				console.log("router() has_role", role in tokenResult.claims);
				if ("admin" in tokenResult.claims || role in tokenResult.claims) {
					next();
					return;
				}
			}
			next("/403");
		});
	} else {
		if (requiresAuth && !auth.currentUser) {
			next("/login");
		} else {
			next();
		}
	}*/
});

export default router;
